import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Stack, Card, Box } from '@mui/material';
import header from "../../../../Images/patrick-tomasso-61MtRBl1qeE-unsplash.jpg";

// Import images for subcategories
import GnssReceiverImage from '../../../../Images/gnssr.png';
import TotalStationImage from '../../../../Images/totalS.png';
import ScannerImage from '../../../../Images/scannerl.png';
import MarineSurveyImage from '../../../../Images/marine.jpg';
import UavImage from '../../../../Images/uav-removebg-preview (1).png';

const subcategoryData = {
  'land-surveying': [
    { title: 'Gnss Receiver', subcategory: 'gnss-receiver', image: GnssReceiverImage, width: '60%', height: '60%' },
    { title: 'Total Station', subcategory: 'total-station', image: TotalStationImage, width: '60%', height: '60%' },
    { title: 'Scanner', subcategory: 'scanner', image: ScannerImage, width: '60%', height: '60%' },
    { title: 'Marine Survey', subcategory: 'marine-survey', image: MarineSurveyImage, width: '80%', height: '80%' },
    { title: 'UAV', subcategory: 'uav', image: UavImage, width: '90%', height: '90%' },
  ],
  // Add other categories and their subcategories if needed
};

const CircularCardContainer = ({ image, category, subcategory, width, height }) => {
  return (
    <Card
      style={{
        position: 'relative',
        width: 190,
        height: 190,
        margin: '10px',
        boxShadow: 'none',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.2)' },
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Link to={`/products/${category}/${subcategory}`} style={{ textDecoration: 'none', width: '100%', height: '100%' }}>
        <img src={image} alt={subcategory} style={{ width: width, height: height, objectFit: 'cover' }} />
      </Link>
    </Card>
  );
};

const SubCategories = () => {
  const { category } = useParams();
  const subcategories = subcategoryData[category] || [];

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundImage: `url(${header})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '70vh',
          width: '100vw',
          maxWidth: '100%',
          margin: 0,
          padding: 0,
          '@media (max-width: 600px)': {
            backgroundAttachment: 'scroll',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'black',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              fontWeight: 'bold',
            }}
          >
            Products
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: '5vmin',
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '50px 30px 0px 30px',
          color: '#0F5A8E',
        }}
      >
        Subcategories
      </Typography>
      <div style={{ padding: '1%', textAlign: 'center' }}>
        <div style={{ padding: '20px 0px 20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%' }}>
              {subcategories.map((data, index) => (
                <Stack key={index} alignItems={'center'}>
                  <CircularCardContainer 
                    image={data.image} 
                    category={category} 
                    subcategory={data.subcategory} 
                    width={data.width}
                    height={data.height}
                  />
                  <Typography style={{ fontSize: '4vmin', fontWeight: 'bold', color: '#78797A' }}>
                    {data.title}
                  </Typography>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategories;
