import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Link } from '@mui/material';
import headerImage from '../../../Images/sean-pollock-PhYq704ffdA-unsplash.jpg';

export const Gallery = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Fetch data from backend
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.vectorinstruments.in/api/getAllVideos');
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Box
        sx={{
          backgroundSize: 'cover',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          paddingBottom: '20px',
          color: 'black',
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          Gallery
        </Typography>
      </Box>
      <Grid container spacing={3} sx={{ padding: 3 }}>
        {videos.map((video) => (
          <Grid item xs={12} md={6} key={video.id}>
            <Card>
              <CardMedia
                component="img"
                height="250"
                image={video.thumbnail}
                alt={video.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {video.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {video.description}
                </Typography>
                <Link href={video.link} target="_blank" rel="noopener" sx={{ mt: 2 }}>
                  Watch Video
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

