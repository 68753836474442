import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

const Page = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
      }}
    >
      <Container>
        <Typography
          align="center"
          style={{ fontSize: "9vmin", fontWeight: "bold" }}
        >
          404 - Not Found
        </Typography>
        <Typography variant="h5" align="center">
          Oops! This Page is Under Development
        </Typography>
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            size="large"
            style={{ backgroundColor: "#F9DC63", color: "black" }}
          >
            Back to Home
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Page;
