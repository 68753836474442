// src/VideoGallery.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import axios from 'axios';

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://api.vectorinstruments.in/api/getAllVideos');
        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const handleRemoveVideo = async (id) => {
    try {
      await axios.delete(`https://api.vectorinstruments.in/api/removeVideos/${id}`);
      setVideos(videos.filter(video => video._id !== id));
    } catch (error) {
      console.error('Error removing video:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Video Gallery
      </Typography>
      <Grid container spacing={3}>
        {videos.map(video => (
          <Grid item xs={12} md={6} key={video._id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={`${video.thumbnail}`}
                alt={video.title}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {video.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {video.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={video.link} target="_blank">
                  Watch
                </Button>
                <Button size="small" color="secondary" onClick={() => handleRemoveVideo(video._id)}>
                  Remove
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VideoGallery;
