import React from 'react';
import { Typography, Stack, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import ScienceIcon from '@mui/icons-material/Science';

// Import images
import LandSurveyingImage from '../../../../Images/lidar.png'; // Adjust the path as needed
import HydrographicSurveyImage from '../../../../Images/marine.jpg';
import LabImage from '../../../../Images/lab.png' // Adjust the path as needed

const cardData = [
  { 
    icon: <img src={LandSurveyingImage} alt="Land Surveying" style={{ width: '80%', height: '80%',  }} />, 
    title: 'Land surveying', 
    category: 'land-surveying', 
    hasSubcategories: true 
  },
  // { 
  //   icon: <img src={HydrographicSurveyImage} alt="Hydrographic Survey" style={{ width: '100%', height: '100%',  }} />, 
  //   title: 'Hydrographic Survey', 
  //   category: 'hydrographic-survey', 
  //   hasSubcategories: false 
  // },
  { 
    icon: <img src={LabImage} alt="Hydrographic Survey" style={{ width: '80%', height: '80%',  }} />, 
    title: 'Lab Equipment', 
    category: 'lab-equipment', 
    hasSubcategories: false 
  },
];

const CircularCardContainer = ({ icon, category, hasSubcategories }) => {
  return (
    <Card
      style={{
        position: 'relative',
        width: 170,
        height: 170,
        boxShadow: 'none',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.2)' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Link to={hasSubcategories ? `/subcategories/${category}` : `/products/${category}`} style={{ textDecoration: 'none', width: '100%', height: '100%' }}>
        {icon}
      </Link>
    </Card>
  );
};

function OurProducts() {
  return (
    <>
      <Typography
        sx={{
          fontSize: '5vmin',
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '50px 30px 0px 30px',
          color: 'black'
        }}
      >
        Our Products
      </Typography>
      <div style={{ padding: '1%', textAlign: 'center' }}>
        <div style={{ padding: '20px 0px 20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%' }}>
              {cardData.map((data, index) => (
                <Stack key={index} alignItems={'center'}>
                  <CircularCardContainer icon={data.icon} category={data.category} hasSubcategories={data.hasSubcategories} />
                  <Typography style={{ fontSize: '4vmin', fontWeight: 'bold', color: '#78797A' }}>
                    {data.title}
                  </Typography>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurProducts;
