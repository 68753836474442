import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const cardsData = [
  { 
    title: 'Mumbai Service center', 
    description: 'Office no- 415, Hilton Center SECTOR-11, CBD BELAPUR, NAVI MUMBAI, MAHARASHTRA-400614 M- 8108880122', 
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3772.1245075034117!2d73.03567127520384!3d19.014234482177688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDAwJzUxLjIiTiA3M8KwMDInMTcuNyJF!5e0!3m2!1sen!2sin!4v1721478026140!5m2!1sen!2sin' 
  },
  { 
    title: 'Pune Office', 
    description: '512/2917, Ist Floor,Badalal,  Beside jagtap House, Near H.A.GroundMahesh Nagar,  Pimpri, Pune- 411018M-8108887322', 
    mapUrl: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3780.849681480555!2d73.8160056751943!3d18.625829682488252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDM3JzMzLjAiTiA3M8KwNDknMDYuOSJF!5e0!3m2!1sen!2sin!4v1721478148917!5m2!1sen!2sin"
  },
  { 
    title: 'Delhi Office/service center', 
    description: 'S3, 2nd floor, Aditya Mall Indrapuram, Ghaziabad-201014 Ph no- 01204956991, 9910015486', 
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7105597926593!2d77.35795717416887!3d28.63843568381495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb07a2a82e57%3A0xb8fad5ecfd54a4be!2sI%20world%20sales%20%26%20service!5e0!3m2!1sen!2sin!4v1721478382991!5m2!1sen!2sin" 
  },
  { 
    title: 'Ahmdabad office/service center', 
    description: '307, kalp Estate, NikolAhmedabad- 382350M- 9998530187', 
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3772.1245075034117!2d73.03567127520384!3d19.014234482177688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDAwJzUxLjIiTiA3M8KwMDInMTcuNyJF!5e0!3m2!1sen!2sin!4v1721478026140!5m2!1sen!2sin' 
  },
  { 
    title: 'Hyderabad Office/service center', 
    description: '1-8-27/14/A/10, Street No.12, opp. Nalla Pochama Temple, Chikkadpally, Hyderabad, Telangana 500020M- 9849754255,9492309742', 
    mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d475.904308544662!2d78.495105!3d17.4005319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99c0d95aef71%3A0x7f2a834073b391c2!2sPS%20INSTRUMENTS%20(Survey%20%26%20Civil%20Lab%20Testing%20Equipment%20Sales%20%2F%20Service)!5e0!3m2!1sen!2sin!4v1721478243107!5m2!1sen!2sin" 
  },
];

export default function ContactCard() {
  return (
    <Box marginTop={"-150px"}>
      <Box backgroundColor={"#97BDE5"} paddingTop={"10px"}>
        <Typography
          sx={{
            fontSize: "5vmin",
            fontWeight: "bold",
            textAlign: "center",
            padding: "0px 30px 40px 30px",
            color:"#0F5A8E"
          }}
        >
          Our Offices
        </Typography>
        <Grid container spacing={2} padding={"0px 50px 50px 80px"}>
          {cardsData.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} paddingBottom={"30px"}>
              <Card sx={{ maxWidth: 345, borderRadius: "40px" }}>
                <iframe
                  title={`Map ${card.title}`}
                  width="100%"
                  height="350"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={card.mapUrl}
                  allowFullScreen
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
