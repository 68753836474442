import './App.css';
import React, { useState, useEffect } from 'react';
import Routers from './Components/Navigation/Routers';
import InquiryFormModal from './Components/Forms/InquiryFormModal'
import AddProductForm from './Components/Forms/AddProductForm';
import ImageUploader from './Components/Forms/ImageUploader';
// import { CardTravelSharp } from '@mui/icons-materi/al';
// import cards from './Components/Countrycode/QuickCards';
// import QuickCards from './Components/Countrycode/QuickCards';
function App() {

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const delayTime = 4000;
    const timerId = setTimeout(() => {
      setModalOpen(true);
    }, delayTime);

    return () => clearTimeout(timerId);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
         <InquiryFormModal isOpen={isModalOpen} onClose={closeModal} />
      <Routers/> 
      {/* <ImageUploader/> */}
      {/* <AddProductForm/> */}
      {/* <QuickCards/> */}
    </div>
  );
}

export default App;
