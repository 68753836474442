// src/AddVideoForm.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Input } from '@mui/material';
import axios from 'axios';
import VideoGallery from './VideoGallery';

const AddVideoForm = () => {
  const [video, setVideo] = useState({
    title: '',
    description: '',
    link: '',
  });
  const [thumbnail, setThumbnail] = useState(null);

  const handleChange = (e) => {
    setVideo({ ...video, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', video.title);
    formData.append('description', video.description);
    formData.append('link', video.link);
    formData.append('thumbnail', thumbnail);

    try {
      await axios.post('https://api.vectorinstruments.in/api/createVideos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Video added successfully!');
      setVideo({ title: '', description: '', link: '' });
      setThumbnail(null);
    } catch (error) {
      console.error('Error adding video:', error);
      alert('Failed to add video.');
    }
  };

  return (
   <Box>
     <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        border: '1px solid #ccc',
        borderRadius: '8px',
        maxWidth: '500px',
        margin: 'auto',
        mt: 12,
        mb:5,
      }}
    >
      <Typography variant="h4" sx={{ mb: 3 }}>
        Add New Video
      </Typography>
      <TextField
        label="Title"
        name="title"
        value={video.title}
        onChange={handleChange}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Description"
        name="description"
        value={video.description}
        onChange={handleChange}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Video Link"
        name="link"
        value={video.link}
        onChange={handleChange}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <Input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        required
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary">
        Add Video
      </Button>
    </Box>
    <VideoGallery/>
   </Box>
  );
};

export default AddVideoForm;
