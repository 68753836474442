import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://api.vectorinstruments.in/api/getAllImages');
        const data = await response.json();
        setImages(data.map(image => image.headerImage));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [images]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <Box
      sx={{
        paddingTop: '69px',
        position: 'relative',
        maxWidth: '100vw', // 100% of viewport width
        overflow: 'hidden',
        height: 'auto', // Adjust height as needed
        margin: 'auto', // Center align horizontally
      }}
    >
      {images.length > 0 ? (
        images.map((image, index) => (
          <Box
            key={index}
            sx={{
              display: index === currentSlide ? 'block' : 'none',
              textAlign: 'center',
              width: '100%', // Ensure image takes full width of parent Box
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ maxWidth: '100%', height: 'auto' }}
              onError={(e) => (e.target.src = 'fallback-image-url.jpg')}
            />
          </Box>
        ))
      ) : (
        <Box>Loading...</Box>
      )}
      <IconButton
        sx={{ position: 'absolute', top: '50%', left: 10, transform: 'translateY(-50%)' }}
        onClick={prevSlide}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        sx={{ position: 'absolute', top: '50%', right: 10, transform: 'translateY(-50%)' }}
        onClick={nextSlide}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default ImageSlider;
