import React from "react";
import ImageScroll from "./SubComps/ImageScroll";
import OurProducts from "../Products/SubComps/OurProducts";
import WhyUs from "./SubComps/WhyUs";
import { Helmet } from "react-helmet";
import industries from "../../../Images/home3.png";
import { Box, Typography, Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NavLink } from "react-router-dom";
import WhyUs2 from './SubComps/WhyUs2'

function Home() {
  return (
    <div style={{ overflow: "hidden" }}>
      <ImageScroll />

      <Helmet>
        <title>Home</title>
      </Helmet>

      <OurProducts />
      
      <img src={industries} alt="industries" style={{ width: "100%" }} />
      {/* <WhyUs /> */}

      {/* New Section for Company Information */}
      <Box
        sx={{
          backgroundColor: '#f9f9f9',
          padding: '40px',
          textAlign: 'center',
          marginTop: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            fontWeight: 'bold',
            color: '#0F5A8E',
            textTransform: 'uppercase'
          }}
        >
          Who Are We?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            lineHeight: '1.6',
            fontSize: '1.1rem',
            color: '#333'
          }}
        >
          We are Vector Instruments, a distinguished company led by Mr. Dhananjay Kumar, who boasts over 30 years of
          experience in Marketing, Training, and Installing various Land Surveying, Soil & Concrete Testing Equipment. 
          With our offices and service centers in Mumbai, Pune, Delhi and ahmedabad we cater to niche markets including Infrastructure, 
          Construction, Airports, Agriculture, Architecture, Mapping Industries, Research Institutes, and Civil Engineering Colleges. 
          Our dedicated 24 x 7 after-sales service and technical support are highly acclaimed and appreciated across the industry.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          
            to="/About-Us"
          sx={{
            marginTop: '20px',
            position: 'relative',
            padding: '12px 24px',
            fontSize: '1rem',
            borderRadius: '4px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Know More
        </Button>
      </Box>
      <WhyUs2/>
    </div>
  );
}

export default Home;
