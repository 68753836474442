import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Contact from "../Screens/Contact/Contact";
import Products from "../Screens/Products/Products";
import WhoAreWe from '../Screens/AboutUs/WhoAreWe';
import VisionMission from "../Screens/AboutUs/VisionMission";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import UBSCart from "../Screens/UBSCart/UBSCart";
import Page from "../Forms/Page";
import ProductsCart from "../Screens/Products/SubComps/ProductsCart";
import ProductDetails from "../Screens/Products/SubComps/ProductDetails";
import SubCategories from "../Screens/Products/SubComps/SubCategory"; // Import Subcategories component
import Login from "../Login/login";
import AddProductForm from "../Forms/AddProductForm";
import ImageUploader from "../Forms/ImageUploader";
import GetProductAndRemove from "../Forms/GetProductAndRemove";
import { Gallery } from "../Screens/Gallery/Gallery"
import AddVideoForm from "../Forms/AddVideoForm";
function Routers() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle login
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/products" element={<Products />} />
        <Route path="/WhoAreWe" element={<WhoAreWe />} />
        <Route path="/About-Us" element={<VisionMission />} />
        <Route path="/products/:category" element={<ProductsCart />} />
        <Route path="/products/:category/:subcategory" element={<ProductsCart />} />
        <Route path="/products/details/:productId" element={<ProductDetails />} />
        <Route path="/subcategories/:category" element={<SubCategories />} />
        <Route path="/UBSCart" element={<UBSCart />} />
        <Route path="/Page" element={<Page />} />
        <Route path="/Login" element={<Login onLogin={handleLogin} />} />
        {/* Conditionally render routes accessible only when logged in */}
        {isLoggedIn ? (
          <>
            <Route path="/add-product" element={<AddProductForm />} />
            <Route path="/add-image" element={<ImageUploader />} />
            <Route path="/GetProductAndRemove" element={<GetProductAndRemove />} />
            <Route path="/addVideoForm" element={<AddVideoForm />} />
            <Route path="/addVideoForm" element={<AddVideoForm />} />

          </>
        ) : (
          // Redirect to login if user tries to access protected routes without logging in
          <>
            <Route path="/add-product" element={<Navigate to="/Login" />} />
            <Route path="/add-image" element={<Navigate to="/Login" />} />
            <Route path="/GetProductAndRemove" element={<Navigate to="/Login" />} />
            <Route path="/addVideoForm" element={<Navigate to="/Login" />} />
          </>
        )}
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routers;
