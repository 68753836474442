import React, { useEffect, useState } from 'react';
import { Typography, Stack, Card, Button } from '@mui/material';

const GetProductAndRemove = () => {
  const [products, setProducts] = useState([]);

  // Fetch all products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.vectorinstruments.in/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Remove a product by ID
  const removeProduct = async (productId) => {
    try {
      const response = await fetch(`https://api.vectorinstruments.in/api/products/${productId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to remove product');
      }
      // Remove the product from the state
      setProducts(products.filter(product => product._id !== productId));
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  return (
    <div>
      <Typography
        sx={{
          fontSize: '5vmin',
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '50px 30px 0px 30px',
          color: '#0F5A8E'
        }}
      >
        Our Products
      </Typography>

      <div style={{ padding: '1%', textAlign: 'center' }}>
        <div style={{ padding: '20px 0px 20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              {products.map((product) => (
                <Stack key={product._id} alignItems={'center'}>
                  <Card style={{ position: 'relative', width: 170, height: 170, margin: '10px', boxShadow: 'none' }}>
                    <img
                      src={`https://api.vectorinstruments.in/app1/uploads/${product.productImage}`}
                      alt={product.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </Card>
                  <Typography style={{ fontSize: '3vmin', fontWeight: 'bold', color: '#78797A' }}>
                    {product.name}
                  </Typography>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => removeProduct(product._id)}
                  >
                    Remove
                  </Button>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetProductAndRemove;
