import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography, } from "@mui/material";
import QuickEnquiry from "..//..//Forms/QuickEnquiry";
import { Helmet } from "react-helmet";
import ContactCard from "./ContactCard";
import headerImage from "../../../Images/sean-pollock-PhYq704ffdA-unsplash.jpg"
import { NavLink } from "react-router-dom";

function Contact() {
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setAnimateText(true);
      } else {
        setAnimateText(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Container sx={{ marginTop: 4 }}>
        <Box
          sx={{
            backgroundSize: 'cover',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            paddingBottom: "20px",
            color: 'black',
            backgroundImage: `url(${headerImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            height: '70vh', // Adjusted height for better visibility
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" sx={{ marginBottom: 2 }}>
            Contact Us
          </Typography>

        </Box>
        <Helmet>
          <title> Contact | ...</title>
        </Helmet>
        <Box>
          <Grid container spacing={2} padding={"40px"}>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#0F5A8E",
                }}
              >
                ADDRESS
              </Typography>
              <Typography textAlign={"left"}>
                SHOP NO-157, PLOT NO-157, AGRAWAL TRADE
                CENTER, SECTOR-11, CBD BELAPUR,
                NAVI MUMBAI, MAHARASHTRA-400614
                

              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#0F5A8E",
                  transitionDelay: "0.1s",
                }}
              >
                EMAIL ADDRESS
              </Typography>
              <Typography textAlign={"left"}>
                admin@vectorinstruments.in
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography
                sx={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#0F5A8E",
                  transitionDelay: "0.2s",
                }}
              >
                PHONE
              </Typography>
              <Typography textAlign={"left"}>
                M- 8108880122,8898529301
                
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography
          sx={{
            fontSize: "6vmin",
            padding: "10px 30px 20px 30px", // Adjusted padding for mobile view
            fontWeight: "bold",
            color: "#0F5A8E",
            textAlign: "left",
          }}
        >
          Have Questions ? Get In Touch !
        </Typography>
        <Grid container style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '0 20px', }}>
          {/* Quick inquiry form */}
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginBottom: '150px' }}>
            <QuickEnquiry />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} marginTop={"-150px"}>
            <Box
              sx={{
                height: "700px",
                width: "100%",
                borderRadius: "30px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: "50px",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3772.1245075034117!2d73.03567127520384!3d19.014234482177688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDAwJzUxLjIiTiA3M8KwMDInMTcuNyJF!5e0!3m2!1sen!2sin!4v1721478026140!5m2!1sen!2sin"
                width="100%"
                height="50%" // Adjusted height for mobile view
                style={{ borderRadius: "30px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <ContactCard />
    </div>
  );
}

export default Contact;
