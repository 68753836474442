import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography, Snackbar, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const AddProductForm = () => {
  const [formData, setFormData] = useState({
    headerImage: null,
    productImage: null,
    name: '',
    description: '',
    features: [],
    highlights: [],
    file: null,
    category: '',
    subcategory: '' // Added subcategory field
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (field) => (event) => {
    if (field === 'headerImage' || field === 'productImage' || field === 'file') {
      setFormData({ ...formData, [field]: event.target.files[0] });
    } else {
      setFormData({ ...formData, [field]: event.target.value });
    }
  };

  const handleAddFeature = () => {
    setFormData({ ...formData, features: [...formData.features, ''] });
  };

  const handleFeatureChange = (index) => (event) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[index] = event.target.value;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddHighlight = () => {
    setFormData({ ...formData, highlights: [...formData.highlights, { title: '', description: '' }] });
  };

  const handleHighlightChange = (index, field) => (event) => {
    const updatedHighlights = [...formData.highlights];
    updatedHighlights[index][field] = event.target.value;
    setFormData({ ...formData, highlights: updatedHighlights });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('headerImage', formData.headerImage);
      formDataToSend.append('productImage', formData.productImage);
      formDataToSend.append('name', formData.name);
      formDataToSend.append('description', formData.description);
      formData.features.forEach((feature, index) => {
        formDataToSend.append(`features[${index}]`, feature);
      });
      formData.highlights.forEach((highlight, index) => {
        formDataToSend.append(`highlights[${index}][title]`, highlight.title);
        formDataToSend.append(`highlights[${index}][description]`, highlight.description);
      });
      formDataToSend.append('file', formData.file);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('subcategory', formData.subcategory); // Append subcategory to form data

      const response = await axios.post('https://api.vectorinstruments.in/api/products', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      setOpenSnackbar(true);
      setFormData({
        headerImage: null,
        productImage: null,
        name: '',
        description: '',
        features: [],
        highlights: [],
        file: null,
        category: '',
        subcategory: '' // Reset subcategory field
      });
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box display="flex" justifyContent="center" border={1} borderColor="primary.main" padding={10} borderRadius={2} marginTop={5}>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
          Product created successfully!
        </MuiAlert>
      </Snackbar>
      <Box width="100%" maxWidth={600}>
        <Typography variant="h4" align="center" gutterBottom>
          Add Product
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="product-image-upload"
                type="file"
                onChange={handleChange('productImage')}
                fullWidth
              />
              <label htmlFor="product-image-upload">
                <Button variant="contained" component="span" fullWidth>
                  Upload Product Image
                </Button>
              </label>
              {formData.productImage && (
                <Typography variant="body1" align="center">
                  {formData.productImage.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Product Name"
                value={formData.name}
                onChange={handleChange('name')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                value={formData.description}
                onChange={handleChange('description')}
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category-select"
                  value={formData.category}
                  onChange={handleChange('category')}
                  variant="outlined"
                  label="Category"
                >
                  <MenuItem value="land-surveying">Land Surveying</MenuItem>
                  <MenuItem value="hydrographic-survey">Hydrographic Survey</MenuItem>
                  <MenuItem value="lab-equipment">Lab Equipment</MenuItem>
                  
                </Select>
              </FormControl>
            </Grid>

            {formData.category === 'land-surveying' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="subcategory-label">Subcategory</InputLabel>
                  <Select
                    labelId="subcategory-label"
                    id="subcategory-select"
                    value={formData.subcategory}
                    onChange={handleChange('subcategory')}
                    variant="outlined"
                    label="Subcategory"
                  >
                    <MenuItem value="gnss-receiver">GNSS Receiver</MenuItem>
                    <MenuItem value="total-station">Total Station</MenuItem>
                    <MenuItem value="scanner">Scanner</MenuItem>
                    <MenuItem value="marine-survey">Marine servey</MenuItem>
                    <MenuItem value="uav">UAV</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Features
              </Typography>
              {formData.features.map((feature, index) => (
                <TextField
                  key={index}
                  fullWidth
                  label={`Feature ${index + 1}`}
                  value={feature}
                  onChange={handleFeatureChange(index)}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                />
              ))}
              <Button onClick={handleAddFeature} variant="outlined" fullWidth>
                Add Feature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <input
                accept=".pdf,.doc,.docx"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleChange('file')}
              />
              <label htmlFor="file-upload">
                <Button variant="contained" component="span" fullWidth>
                  Upload File
                </Button>
              </label>
              {formData.file && (
                <Typography variant="body1" align="center">
                  {formData.file.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      
    </Box>
  );
};

export default AddProductForm;
