import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, List, ListItem, ListItemSecondaryAction, IconButton, Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ImageUploader = () => {
    const [file, setFile] = useState(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchImages();
    }, []);
    const fetchImages = async () => {
        try {
          const response = await axios.get('https://api.vectorinstruments.in/api/getAllImages');
          if (response.status === 200) {
            setImages(response.data);
          } else {
            console.error('Failed to fetch images', response.status);
          }
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };
      
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) return; // Add check for no file selected

        const formData = new FormData();
        formData.append('headerImage', file);

        try {
            await axios.post('https://api.vectorinstruments.in/api/addImage', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setFile(null);
            fetchImages();  // Fetch images after uploading
        } catch (error) {
            console.error('Error uploading image', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.vectorinstruments.in/api/images/${id}`);
            fetchImages();  // Fetch images after deleting
        } catch (error) {
            console.error('Error deleting image', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8, p: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Image Uploader
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="image/png, image/jpeg"
                            style={{ display: 'none' }}
                            id="upload-button"
                        />
                        <label htmlFor="upload-button">
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                Choose Image
                            </Button>
                        </label>
                        {file && <Typography sx={{ ml: 2 }}>{file.name}</Typography>}
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        disabled={!file}
                    >
                        Upload Image
                    </Button>
                </form>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List sx={{ mt: 2 }}>
                        {images.length === 0 ? (
                            <Typography>No images available</Typography>
                        ) : (
                            images.map((image) => (
                                <ListItem key={image._id} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={`${image.headerImage}`}
                                        alt="Header"
                                        style={{ maxWidth: '100px', marginRight: '16px' }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(image._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        )}
                    </List>
                )}
            </Box>
        </Container>
    );
};

export default ImageUploader;
