import React, { useState } from 'react';
import { Container, Box, TextField, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    notARobot: false,
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setFormData({ ...formData, notARobot: event.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.vectorinstruments.in/api/login', {
        email: formData.email,
        password: formData.password,
      });

      localStorage.setItem('token', response.data.token); // Store token in localStorage
      onLogin(); // Call the onLogin function to update the login state
      navigate('/add-product'); // Redirect using useNavigate
    } catch (error) {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: blue[50],
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 3,
          boxShadow: 3,
          backgroundColor: 'white',
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: blue[700] }}>
          Login
        </Typography>
        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            value={formData.email}
            onChange={handleChange('email')}
            required
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={formData.password}
            onChange={handleChange('password')}
            required
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="I'm not a robot"
            checked={formData.notARobot}
            onChange={handleCheckboxChange}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2, backgroundColor: blue[700], ':hover': { backgroundColor: blue[800] } }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
