import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Grid, Typography, CardMedia, Box, Container } from '@mui/material';
import header from "../../../../Images/patrick-tomasso-61MtRBl1qeE-unsplash.jpg";

const ProductsCart = () => {
  const { category, subcategory } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(subcategory 
          ? `https://api.vectorinstruments.in/api/products/${category}/${subcategory}`
          : `https://api.vectorinstruments.in/api/products/${category}`);
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [category, subcategory]);

  if (!products.length) {
    return (
      <Typography variant="h6" align="center">
        No products found.
      </Typography>
    );
  }

  const handleViewMore = (product) => {
    navigate(`/products/details/${product._id}`, {
      state: {
        product: {
          ...product,
          imageUrl: `https://api.vectorinstruments.in/uploads/${product.productImage}`
        }
      }
    });
  };

  return (
    <Container sx={{ marginTop: 10 }}>
     <Box
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundImage: `url(${header})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '60vh',
          width: '100vw',
          maxWidth: '100%',
          margin: 0,
          padding: 0,
          '@media (max-width: 600px)': {
            backgroundAttachment: 'scroll',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'black',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              fontWeight: 'bold',
            }}
          >
            Products
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3} padding={"30px"}>
        {products.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                src={`https://api.vectorinstruments.in/app1/uploads/${product.productImage}`}
                alt={product.productImage}
              />
              <CardContent sx={{ textAlign: 'left' }}>
                <Typography gutterBottom variant="h6" component="div">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  onClick={() => handleViewMore(product)}
                >
                  View More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsCart;
