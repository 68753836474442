import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Typography, Box, CardMedia, Grid, Paper, Button } from '@mui/material';
import header from '../../../../Images/patrick-tomasso-61MtRBl1qeE-unsplash.jpg';
import QuickEnquiry from '../../../Forms/QuickEnquiry';

const ProductDetails = () => {
  const { productId } = useParams();
  const location = useLocation();
  const [product, setProduct] = useState(location.state?.product || null);
  const [features, setFeatures] = useState([]);
  const [productManualFile, setProductManualFile] = useState('');

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (!product) {
        try {
          const response = await fetch(`https://api.vectorinstruments.in/api/getProductsById/${productId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch product details');
          }
          const data = await response.json();
          setProduct(data);
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      }
    };

    fetchProductDetails();
  }, [productId, product]);

  useEffect(() => {
    if (product) {
      const fetchProductFeatures = async () => {
        try {
          const response = await fetch(`https://api.vectorinstruments.in/api/${product._id}/features`);
          if (!response.ok) {
            throw new Error('Failed to fetch product features');
          }
          const data = await response.json();
          setFeatures(data || []);
        } catch (error) {
          console.error('Error fetching product features:', error);
        }
      };

      const fetchProductFile = async () => {
        try {
          const response = await fetch(`https://api.vectorinstruments.in/api/${product._id}/file`);
          if (!response.ok) {
            throw new Error('Failed to fetch product file');
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setProductManualFile(url);
        } catch (error) {
          console.error('Error fetching product file:', error);
        }
      };

      fetchProductFeatures();
      fetchProductFile();
    }
  }, [product]);

  const handleDownload = () => {
    if (productManualFile) {
      const anchor = document.createElement('a');
      anchor.href = productManualFile;
      anchor.download = productManualFile.split('/').pop();
      anchor.click();
    }
  };

  const handleInquiryClick = () => {
    const quickEnquiryForm = document.getElementById('quickEnquiryForm');
    if (quickEnquiryForm) {
      quickEnquiryForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!product) {
    return (
      <Typography variant="h6" align="center">
        Product not found.
      </Typography>
    );
  }

  return (
    <Box>
     <Box
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundImage: `url(${header})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '60vh',
          width: '100vw',
          maxWidth: '100%',
          margin: 0,
          padding: 0,
          '@media (max-width: 600px)': {
            backgroundAttachment: 'scroll',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'black',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              fontWeight: 'bold',
            }}
          >
            Products
          </Typography>
        </Box>
      </Box>
      <Container sx={{ marginTop: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              src={`https://api.vectorinstruments.in/app1/uploads/${product.productImage}`}
              alt={product.name || 'Product Image'}
              onError={(e) => {
                e.target.src = 'path_to_fallback_image'; // Provide a path to a fallback image
              }}
              sx={{ width: '100%', height: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h4" component="div" gutterBottom>
                {product.name}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginTop: '10px' }}>
                {product.description}
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Button variant="contained" color="primary" size="large" onClick={handleInquiryClick}>
                  Inquiry
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h4" component="div" sx={{ marginBottom: 4, textAlign: "left" }}>
            Features
          </Typography>
          <Grid container spacing={2}>
            {features.length > 0 ? (
              features.map((feature, index) => (
                <Grid item key={index} xs={12} lg={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: '20px',
                      textAlign: 'center',
                      borderRadius: '8px',
                    }}
                  >
                    {feature}
                  </Paper>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" align="center">
                No features available.
              </Typography>
            )}
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4, textAlign: 'center', paddingBottom: "5vmin" }}>
          <Button variant="contained" onClick={handleDownload}>
            Download Product Manual
          </Button>
        </Box>
      </Container>

      <QuickEnquiry id="quickEnquiryForm" />
    </Box>
  );
};

export default ProductDetails;
