import React from "react";
import image1 from "..//..//..//..//Images/2.jpg";
import image2 from "..//..//..//..//Images/2.jpg";
import image3 from "..//..//..//..//Images/2.jpg";
import image4 from "..//..//..//..//Images/2.jpg";
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

function CardAboutUs() {
  const cards = [
    {
      image: image1,
      title: "Free Online Demo",
      description: `Our free demo will give you a first-hand view of a client's experiences when they use our software.`,
    },
    {
      image: image2,
      title: "Customer Service",
      description: `We strive to provide superior customer service and ensure that every client is completely satisfied with our work.`,
    },
    {
      image: image3,
      title: "Support",
      description: `Our teams are trustworthy, dedicated and experienced and will go the extra mile to help you.`,
    },
    {
      image: image4,
      title: "Quality",
      description: ` cutting edge business solutions that add real value that goes beyond what is expected.`,
    },
  ];

  return (
    <div>
      <Box backgroundColor={"#D6E5F5"} paddingTop={"10px"}>
      <Typography
        sx={{
          fontSize: "5vmin",
          fontWeight: "bold",
          textAlign: "center",
          padding: "50px 30px 0px 30px",
          color:"#0F5A8E"
        }}
      > INDUSTRIES
      </Typography>
      <Grid container spacing={2} padding={"4%"} >
        {cards.map((card, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={6}
            md={3}
            lg={3}
            alignItems={"space-between"}
            
          >
            <Card
              sx={{
                height: "100%", 
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                boxShadow:"none",
                textAlign:"left",
                backgroundColor:"#D6E5F5"
              }}
            >
              <CardMedia
                component="img"
                image={card.image}
                sx={{
                  maxHeight: "15vmin",
                  maxWidth: "15vmin",
                  margin: " auto",
                  marginTop: "5%",
                  transition: "transform 0.3s", 
                  "&:hover": {
                    transform: "scale(1.2)", 
                  },
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    marginTop: "3%",
                    fontSize: "4vmin",
                    color:"#0F5A8E"
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ marginTop: "3%", fontSize: "3vmin" ,color:"#78797A"}}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      </Box>
    </div>
  );
}

export default CardAboutUs;
