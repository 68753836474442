import React, { useState, useEffect, useRef } from "react";
import { Typography, Stack, Button, Grid, Box, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import navin from "../../../../Images/2e5b3f86-e7b5-426f-89dc-df521af8c691.png_560xaf.png"

const cardData = [
  { title: "Strategic Alliances", description: "Vector has formed alliances with leading brands such as Sinognss by Comnav, known for its advanced GNSS technology. We also partner with Leica and Ideaforge to provide cutting-edge equipment in surveying and drone technology respectively.", path: "/employees" },
  { title: "Laboratory Products", description: "Vector Instruments is known for durable laboratory products serving the construction industry. We offer reliable testing instruments for soil aggregates, concrete, asphalt, cement, and bitumen.", path: "/countries" },
  { title: "After-Sales Service", description: "We provide exceptional 24x7 after-sales service and technical support, ensuring our customers receive comprehensive assistance whenever they need it.", path: "/industries" },
];

const solutionBoxStyle = {
  padding: "1%",
  textAlign: "center",
  backgroundColor: "#0F5A8E",
  padding:"50px",
  margin: "20px",
  opacity: 0,
  width:"400px",
  color:"white",
  transform: "translateY(100px)",
  transition: "opacity 1s ease-out, transform 1s ease-out",
};

const useIntersection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return [isVisible, elementRef];
};

function WhatisPOS() {
  const [isVisible, elementRef] = useIntersection();
  const navigate = useNavigate();

  return (
    <>
      {/* <Typography
        sx={{
          fontSize: "5vmin",
          fontWeight: "bold",
          textAlign: "center",
          padding: "50px 30px 0px 30px",
          color: "#0F5A8E",
        }}
      >
        Why Us ?
      </Typography> */}

      <div ref={elementRef} style={{ padding: "0px 0px 20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              width: "100%",
              backgroundColor:"white",
              padding:"60px"
            }}
          >
            {cardData.map((data, index) => (
              <Stack key={index} alignItems={"center"} style={{ ...solutionBoxStyle, ...(isVisible ? { opacity: 1, transform: "translateY(0)" } : {}) }}>
                <Typography style={{ fontSize: "5vmin", fontWeight: "bold", paddingBottom:"12px" }}>
                  {data.title}
                </Typography>
                <Typography style={{ fontSize: "3vmin",  }}>
                  {data.description}
                </Typography>
               
              </Stack>
            ))}
          </div>
        </div>
      </div>

      {/* <Grid container spacing={2} sx={{ width: "100%", marginTop: "20px" }}>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "#0F5A8E", padding: "40px" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "#FFFFFF",
              marginBottom: "10px",
              textAlign:"left",
              padding:"20px 10px 0px 10px"
            }}
          >
            Why Choose Us ?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#FFFFFF",
              marginBottom: "10px",
              textAlign:"left",
              padding:"20px"

            }}
          >
            We acknowledge the challenges you face in finding a reliable plating partner, we are confident in our ability to offer you effective solutions that meet your needs.
          </Typography>
          <ul style={{ color: "#FFFFFF",textAlign:"left",              
          padding:"0px 30px 10px 30px"
            }}>
            <li>Experts on hand to ensure you always have the correct plating solution defined, in advance of processing your parts.</li>
            <li>Full capability to support prototypes, trials, and validations with necessary industry qualification, such as PSW / PPAP.</li>
            <li>High level of flexibility across our range of finishes, we manage high and low volumes, and can build (in-house) specific process lines to suit most applications.</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="your-image-path.jpg"
            alt="Why Choose Navin"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
          />
        </Grid>
      </Grid> */}
    </>
  );
}

export default WhatisPOS;
