import React, { useState } from "react";
import "../Navbar/Navbar.css";
import { Link, NavLink } from 'react-router-dom';
import Hidden from "@mui/material/Hidden";
import CloseIcon from '@mui/icons-material/Close';
import LOGO from "../../Images/file (1).png"
import { Typography } from "@mui/material";
const Navbar = ({ isLoggedIn, onLogout }) => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleDrawer = () => {
    setShowLinks(!showLinks);
  };

  const closeDrawer = () => {
    setShowLinks(false);
  };

  const renderLoggedInLinks = () => (
    <>
     <li>
      <NavLink to="/add-product" onClick={closeDrawer}>
        Add Product
      </NavLink>
      </li>
      <li>
      <NavLink to="/AddVideoForm" onClick={closeDrawer}>
        Add Video
      </NavLink>
      </li>
      <li>
      <NavLink to="/add-image" onClick={closeDrawer}>
        Add Image
      </NavLink>
      </li>
      <li>
      <NavLink to="/GetProductAndRemove" onClick={closeDrawer}>
      All Products
      </NavLink>
      </li>
      <li>
      <button onClick={onLogout}>Logout</button>
      </li>
    </>
  );

  const renderLoggedOutLinks = () => (
    <>
      <li>
        <NavLink to="/" onClick={closeDrawer}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/About-Us" onClick={closeDrawer}>
          About
        </NavLink>
      </li>
      <li>
        <NavLink to="/products" onClick={closeDrawer}>
          Products
        </NavLink>
      </li>
      <li>
        <NavLink to="/gallery" onClick={closeDrawer}>
          Gallery
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact-us" onClick={closeDrawer}>
          Contact
        </NavLink>
      </li>
     
      {/* <li>
        <NavLink to="/Login" onClick={closeDrawer}>
          Login
        </NavLink>
      </li> */}
    </>
  );

  return (
    <nav className="navbar">
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
         <img src={LOGO} alt="logo" height={"60px"}/><Typography paddingLeft={"8px"} color={"black"} fontSize={"5vmin"} fontWeight={"bold"}>Vector Instruments</Typography>

      </Link>
      <div className="nav-links-container">
        <ul className={showLinks ? "nav-links mobile" : "nav-links"}>
          {isLoggedIn ? renderLoggedInLinks() : renderLoggedOutLinks()}
        </ul>
      </div>

      <Hidden mdUp>
        {showLinks ? (
          <button className="toggle-button" onClick={toggleDrawer}>
            <CloseIcon />
          </button>
        ) : (
          <button className="toggle-button" onClick={toggleDrawer}>
            ☰
          </button>
        )}
      </Hidden>
    </nav>
  );
};

export default Navbar;
