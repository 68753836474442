import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box,Typography } from '@mui/material';
import headerImage from '../../../Images/sean-pollock-PhYq704ffdA-unsplash.jpg';
import OurProducts from './SubComps/OurProducts';

function Products() {
  return (
    <div style={{ paddingTop: '0px', margin: 0 }}>
      <Helmet>
        <title>Products | ...</title>
      </Helmet>
      <Container sx={{ marginTop: 0, padding: 0, maxWidth: '100%' }}>
      <Box
        sx={{
          backgroundSize: 'cover',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          paddingBottom: "20px",
          color: 'black',
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '70vh', // Adjusted height for better visibility
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
           Products
        </Typography>
        
      </Box>
        <OurProducts />
      </Container>
    </div>
  );
}

export default Products;
