import React from 'react';
import { Container, Grid, Typography, Box, Hidden } from '@mui/material';
import image1 from "../../../Images/Rectangle 268.png";
import image2 from "../../../Images/Rectangle 269.png";
import image3 from "../../../Images/Rectangle 270.png";
import image4 from "../../../Images/Rectangle 271.png";
import headerImage from "../../../Images/sean-pollock-PhYq704ffdA-unsplash.jpg"
const AboutUsPage = () => {
  return (
    <Container sx={{ marginTop: 12 }}>
      {/* Header Section with Image */}
      <Box
        sx={{
          backgroundSize: 'cover',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          paddingBottom: "20px",
          color: 'black',
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '60vh', // Adjusted height for better visibility
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          About Us
        </Typography>

      </Box>
      <Box sx={{ padding: "40px" }}>
        <Typography variant="h5" sx={{ padding: "30px", color: "#0F5A8E", fontWeight: "bold" }} >
          Built and owned by Mr. Dhananjay Kumar with over 30 years of experience
        </Typography>
        <Typography sx={{ padding: "20px" }}>
          years  in the field of Marketing, Training, Installations of various kinds of Land Surveying, Soil & Concrete Testing Equipment’s. We are having our office and Service centre in Mumbai, Pune, Delhi, focusing on niche products for the Infrastructure, Construction, Airports, Agriculture, Architecture, Mapping Industries, along with Research Institutes & Civil Engineering Colleges. Our excellent 24 x 7 after-sales service and technical support are very well-acknowledged and appreciated by the market.
          Vector has made strategic alliances for India with leading brands in Geosystems.
          Sinognss by Comnav is one of the leading/demanding brand in India for Surveyor choice.  Comnav provides latest/leading technology in this Field.  Comnav first to launch Laser Technology in GNSS Receiver. Comnav provides a wide range of Surveying Equipments including GNSS Receivers, CORS Stations, Total Stations, 3D Laser Scanners, Marine Survey Products.
          Leica -One of the Leading Land Survey Company in the World.  Leica provides wide range of Total Stations.
          Ideaforge- One of the Leading Company in India for Drones.
          Vector also known for its standard and durable Laboratory Products for the Construction Industry.  Vector delivers Testing Instruments for Soil Aggregate, Concrete, Asphalt, Cement and Bitumen.

        </Typography>
      </Box>
      {/* Second Container */}
      <Container sx={{ marginTop: 4 }}>
        {/* Vector Instruments Overview Section */}
        <Grid container spacing={3} marginBottom={"5%"}>
          {/* Image Section */}
          <Grid item xs={12} sm={6}>
            <img
              src={image1}
              alt="Vector Instruments"
              style={{
                width: "80%",
                height: "80%",
                marginTop: "20px",
                marginLeft: "15px",
                borderRadius: "8px", // Adding borderRadius to match the design
              }}
            />
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                p: "20px 30px 30px 50px",
                textAlign: "left",
                backgroundColor: "#EEEEEE",
                borderRadius: "8px", // Adjusting borderRadius to match the image
              }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", fontSize: "5vmin" }}
              >
                Vector Instruments Overview
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                Vector Instruments specializes in marketing, training, and installing land surveying, soil, and concrete testing equipment. We have offices and service centers in Mumbai, Pune, and Delhi, serving various industries and educational institutions.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Strategic Alliances Section */}
        <Grid container spacing={3} marginBottom={"5%"}>
          {/* Image Section */}


          {/* Text Section */}
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={image2}
                alt="Strategic Alliances"
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "20px",
                  marginLeft: "15px",
                  borderRadius: "8px", // Adding borderRadius to match the design
                }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>

            <Box
              sx={{
                p: "20px 30px 30px 50px",
                textAlign: "left",
                backgroundColor: "#EEEEEE",
                borderRadius: "8px", // Adjusting borderRadius to match the image
              }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", fontSize: "5vmin" }}
              >
                Strategic Alliances
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                VECTOR has formed alliances with leading brands such as SinoGNSS by Comnav, known for its advanced GNSS technology. We also partner with Leica and IDEAFORGE to provide cutting-edge equipment in surveying and drone technology respectively.
              </Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6}>
              <img
                src={image2}
                alt="Strategic Alliances"
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "20px",
                  marginLeft: "15px",
                  borderRadius: "8px", // Adding borderRadius to match the design
                }}
              />
            </Grid>
          </Hidden>
        </Grid>

        {/* Laboratory Products Section */}
        <Grid container spacing={3} marginBottom={"5%"}>
          {/* Image Section */}
          <Grid item xs={12} sm={6}>
            <img
              src={image3}
              alt="Laboratory Products"
              style={{
                width: "80%",
                height: "80%",
                marginTop: "20px",
                marginLeft: "15px",
                borderRadius: "8px", // Adding borderRadius to match the design
              }}
            />
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                p: "20px 30px 30px 50px",
                textAlign: "left",
                backgroundColor: "#EEEEEE",
                borderRadius: "8px", // Adjusting borderRadius to match the image
              }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", fontSize: "5vmin" }}
              >
                Laboratory Products
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                Vector Instruments is known for durable laboratory products serving the construction industry. We offer reliable testing instruments for soil aggregates, concrete, asphalt, cement, and bitumen.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* After-Sales Service Section */}
        <Grid container spacing={3}>

          <Hidden smUp>
            {/* Image Section */}
            <Grid item xs={12} sm={6}>
              <img
                src={image4}
                alt="After-Sales Service"
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "20px",
                  marginLeft: "15px",
                  borderRadius: "8px", // Adding borderRadius to match the design
                }}
              />
            </Grid>
          </Hidden>
          {/* Text Section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                p: "20px 30px 30px 50px",
                textAlign: "left",
                backgroundColor: "#EEEEEE",
                borderRadius: "8px", // Adjusting borderRadius to match the image
              }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", fontSize: "5vmin" }}
              >
                After-Sales Service
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "3vmin", pt: "20px" }}>
                We provide exceptional 24x7 after-sales service and technical support, ensuring our customers receive comprehensive assistance whenever they need it.
              </Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            {/* Image Section */}
            <Grid item xs={12} sm={6}>
              <img
                src={image4}
                alt="After-Sales Service"
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "20px",
                  marginLeft: "15px",
                  borderRadius: "8px", // Adding borderRadius to match the design
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Container>
  );
}

export default AboutUsPage;
