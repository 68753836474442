import React, { useEffect } from "react";
import { Typography, Container, Grid, Box, IconButton, Button } from "@mui/material";
import { NavLink, useLocation } from 'react-router-dom';
import { YouTube, Instagram, Mail } from "@mui/icons-material";

const Footer = () => {
  const customStyle = {
    fontWeight: "bold",
    paddingBottom: "10px",
    textAlign: "left"
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  const upcomingEvents = [
    {
      date: "30 Jan - 02 Feb",
      title: "Constro 2025",
      description: "PCERF presents India's largest integrated constructions expo"
    },
  ];

  return (
    <>
      <hr />
      <footer
        style={{
          padding: "1rem 2rem",
          backgroundPosition: "center",
          color: "black",
          backgroundColor: "#FFFFFF"
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" style={customStyle}>Contact Us</Typography>
              <hr />
              <br />
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="outlined" component={NavLink} to="/contact-us" style={{ padding: "10px 20px", fontWeight: "bold" }}>
                    SERVICES AND SUPPORT
                  </Button>
                </Grid>
              </Grid>
              <Typography sx={{ textAlign: "left", paddingTop: "20px" }}>
                Office no- 415, Hilton Center <br />
                SECTOR-11, CBD BELAPUR,  <br />
                NAVI MUMBAI, MAHARASHTRA-400614 <br />
                M- 8108880122,8898529301

                <br />
                Email: admin@vectorinstruments.in
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" style={customStyle}>Upcoming Events</Typography>
              <hr />
              <br />
              {upcomingEvents.map((event, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Typography style={{ textAlign: "left", fontSize: "3.5vmin", fontWeight: "bold" }}>
                    <Button
                      component="span" // Change to a span to avoid showing the URL
                      style={{
                        fontSize: "3.5vmin",
                        color: "black",
                        fontWeight: "bold",
                        padding: 0,
                        minWidth: "auto",
                        minHeight: "auto",
                        lineHeight: 1,
                        textTransform: "none",
                      }}
                    >
                      <NavLink
                        to="/Login"
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        @
                      </NavLink>
                    </Button> {event.title}
                  </Typography>
                  <Typography style={{ textAlign: "left", fontSize: "3vmin", }}>
                    {event.date}
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: "2.5vmin" }}>
                    {event.description}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </Container>
        <Grid container justifyContent="flex-start" alignItems="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <IconButton>
                <a
                  href="https://youtube.com/@vectorinstruments-t7w?si=9nlwpHECf_f0S8F7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTube style={{ color: "#000" }} />
                </a>
              </IconButton>
              <IconButton>
                <a
                  href="https://www.instagram.com/vector_instruments?igsh=MXBiajZkdTc2c3F6cQ%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram style={{ color: "#000" }} />
                </a>
              </IconButton>
              <IconButton>
                <a href="mailto:Vector.instruments@yahoo.com">
                  <Mail style={{ color: "#000" }} />
                </a>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </footer>
      <hr
        style={{ border: "none", borderTop: "1px solid black", margin: "0 0" }}
      />
      <Box
        sx={{
          width: '100%',
          height: '50px',
          backgroundColor: '#0F5A8E',
          textAlign: 'center',
          padding: '10px',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        © 2024 Umbrella WebFX. All rights reserved.
      </Box>
    </>
  );
};

export default Footer;
