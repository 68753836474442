import React from "react";
import Page from "..//..//Forms/Page";
import { Helmet } from "react-helmet";

function UBSCart() {
  return (
    <div>
      <Helmet>
        <title> UBSCart | UBS Bill</title>
      </Helmet>
      <Page />
    </div>
  );
}

export default UBSCart;
