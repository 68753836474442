import React from 'react';
import { Modal, Box, IconButton, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import eventImage from '../../Images/wrgw.png'; // Ensure this path is correct

const InquiryFormModal = ({ isOpen, onClose }) => {
  const handleSubmit = () => {
    // Redirect to the contact-us page and replace the current history entry
    window.location.replace('/contact-us');
    onClose(); // Close the modal after navigation
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: 500,
          bgcolor: "white",
          boxShadow: 24,
          backgroundColor: "#d9d9d9",
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            width: 0,
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0, color: "black" }}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
        <img
          src={eventImage}
          alt="Event Image"
          style={{ width: "100%", height: "50%", marginBottom: "16px" }}
        />
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#fff",
            fontSize: "0.8rem",
            textTransform: "none",
            color: "black",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "2px",
            marginTop: "8px",
            ":hover": {
              backgroundColor: "#FFD580",
            },
          }}
        >
          Register
        </Button>
      </Box>
    </Modal>
  );
};

export default InquiryFormModal;
