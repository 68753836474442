import React, { useState } from "react";
import { Grid, Box, Typography, Hidden, Stack, Card } from "@mui/material";
import image1 from "..//..//..//Images/banner img 2.png";
import image2 from "..//..//..//Images/Rectangle 322.png";
import image3 from "..//..//..//Images/image 4.png";
import image4 from "..//..//..//Images/image 6.png";
import image5 from "..//..//..//Images/image 7.png";
import image6 from "..//..//..//Images/travel agency 1.png";
import image7 from "..//..//..//Images/Rectangle 323.png";
import CardAboutUs from "./SubComps/CardAboutUs";
import { Helmet } from "react-helmet";

const cardData = [
  { imageUrl: image3, title: "Restaurant POS Software" },
  { imageUrl: image5, title: "Salesman Tracker" },
  { imageUrl: image4, title: "CRM Software" },
  { imageUrl: image6, title: "Travel Agency Software" },
];


const CircularCardContainer = ({ imageUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const circularCardStyle = {
    position: "relative",
    width: 100,
    height: 100,
    backgroundColor: "#F5EECE",
    margin: "20px",
    boxShadow: "none",
    transition: "transform 0.3s",
    transform: isHovered ? "scale(1.2)" : "scale(1)",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <Card
      style={circularCardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageUrl} alt="icon" style={imageStyle} />
    </Card>
  );
};

const solutionBoxStyle = {
  backgroundColor: "#F5EECE",
  textAlign: "center",
  
};

function WhoAreWe() {
  return (
    <>
     <Helmet>
        <title> About | ...</title>
      </Helmet>
      <Grid container spacing={3} backgroundColor={"#FAE5E2"}>
        <Grid item xs={12} sm={6}>
          <img
            src={image1}
            alt="Innovation"
            style={{
              width: "90%",
              height: "90%",
              marginTop: "9%",
              marginLeft: "15px",
            }}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Hidden smDown>
            <Box sx={{ pt: "30%", textAlign: "left" }}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  fontSize: "5vmin",
                  color: "#C62717",
                  textAlign: "center",
                  marginTop: "60px",
                  padding:"5px"
                }}
              >
                Helping Every Size Of Business <br/> to Succeed
              </Typography>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box sx={{ p: "5%", textAlign: "left" }}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  fontSize: "5vmin",
                  color: "#C62717",
                  textAlign: "center",
                }}
              >
                Helping Every Size Of Business to Succeed
              </Typography>
            </Box>
          </Hidden>
        </Grid>
      </Grid>
      <Box>
        <Typography
          sx={{ fontSize: "5vmin", fontWeight: "bold", paddingTop: "30px" }}
        >
          Customizable solution built for you
        </Typography>
        <Typography
          sx={{ textAlign: "justify", fontSize: "3vmin", padding: "30px" }}
        >
          Our team comprises of industry experts and innovators who constantly
          develop newer and better solutions that directly cater to the present
          needs of the industry. Our industry veterans with domain expertise
          understand the needs of the industry better than anyone. The
          innovators in our team develop features and functionalities that are
          ahead of the curve and add value over and above the basics. This is
          how our solutions ensure effectiveness and efficiency while offering a
          competitive edge. Our developers understand that solutions should be
          user-friendly and all functionalities need to be designed from a
          user-centric philosophy. This has enabled us to develop solutions that
          have consistently solicited praise from our clients. As an
          organization that believes in leveraging technology for business
          value, our solutions are equipped with the best technologies that the
          21st century has to offer such as AI, Cloud Technology, Business
          Process Automation, Biometrics Integration, Voice Command Interface,
          and Complete Digital Media Integration. We educate our clients to make
          informed decisions based on their needs and guide them through various
          stages of software selection and implementation. Our service to our
          customers spans from consultation to successful implementation.
        </Typography>
      </Box>
      <Grid container spacing={3} marginBottom={"5%"}>
        <Grid item xs={12} sm={6}>
          <img
            src={image2}
            alt="Innovation"
            style={{
              width: "80%",
              height: "80%",
              marginTop: "20px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={5}>
          <Box
            sx={{
              p: "20px 30px 30px 50px",
              textAlign: "left",
              backgroundColor: "#EEEEEE",
              borderRadius: "70px 0px 0px 70px",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", fontSize: "5vmin" }}
            >
              What We Do?
            </Typography>
            <Typography variant="h4" sx={{ fontSize: "3vmin", pt: "20px" }}>
              UBS Bill is a IT Solution base company looking to provide a more
              personal touch to an increasingly less personal industry. We aim
              to provide you with all the information you need and assist you
              with each step in the process. It provides IT Solutions with
              Advanced Technology.<br/>  We develop solutions that are meant to
              enhance productivity and profitability by focusing on crucial
              business functions as well as auxiliary ones. We improve the
              functioning of elements such as sound communication and
              coordination between all departments, enterprises, travel agency,
              retail business, inventory management, restaurants management, and
              customer relationship management.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <div style={solutionBoxStyle}>
        <div style={{ padding: "20px 0px 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {cardData.map((data, index) => (
                <Stack key={index} alignItems={"center"}>
                  <CircularCardContainer imageUrl={data.imageUrl} />
                  <Typography style={{ fontSize: "4vmin", fontWeight: "bold" }}>
                    {data.title}
                  </Typography>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Grid container spacing={3} paddingTop={"30px"}>
        <Grid item xs={12} sm={6}>
          <img
            src={image7}
            alt="Innovation"
            style={{
              width: "80%",
              height: "80%",
              marginTop: "20px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              p: "20px 30px 30px 50px",
              textAlign: "left",
              backgroundColor: "#EEEEEE",
              borderRadius: "70px 0px 0px 70px",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", fontSize: "5vmin" }}
            >
              Why Choose Us?
            </Typography>
            <Typography variant="h4" sx={{ fontSize: "3vmin", pt: "20px" }}>
              UBS Bill Solution have the expertise and resources required to
              design, develop and manage the highly available and highly secure
              technology platform that you need, giving you the time and
              confidence to focus on running your business. <br/> Here are 4 reasons
              why you should choose us to build your infrastructure, support
              your people and systems, enhance your productivity and give you a
              real competitive edge
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <CardAboutUs />
    </>
  );
}

export default WhoAreWe;
