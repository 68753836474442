import React, { useState } from "react";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import axios from "axios";
import CustomSnackbar from "../Forms/Customsnackbar";

function QuickEnquiry() {
  const [formData, setFormData] = useState({
   
    name: "",
    phoneno: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    businessname: "",
    price: "",
    reservations: "",
    name: "",
    phoneno: "",
    email: "",
    message: "",
  });

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let errorMessage = "";

    switch (field) {
      case "phoneno":
        value = value.replace(/\D/g, "").slice(0, 10);
        errorMessage = !/^[6-9]\d{9}$/.test(value)
          ? "Please enter a valid 10-digit number"
          : "";
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;

      default:
        value = value.replace(/[^a-zA-Z0-9 ]/g, ""); // Adjusted regex for alphanumeric
        break;
    }

    setErrors({ ...errors, [field]: errorMessage });
    setFormData({ ...formData, [field]: value });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [ "name", "phoneno", "email", "message"];
    const emptyFields = [];
    for (const field of requiredFields) {
      const fieldValue =
        typeof formData[field] === "string"
          ? formData[field].trim()
          : formData[field];
      if (!fieldValue) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      setSnackbarMessage(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      setOpenSnackbar(true);
      return;
    }

    if (
     
      errors.name ||
      errors.phoneno ||
      errors.email ||
      errors.message
    ) {
      setSnackbarMessage("Please fix validation errors");
      setOpenSnackbar(true);
      return;
    }

    try {
      // Send form data to backend using Axios
      const response = await axios.post(
        "https://api.vectorinstruments.in/api/quick-enquiry-form",
        formData
      );

      console.log(response.data); // Handle response from server as needed

      setSuccessSnackbarOpen(true);
      setFormData({
       
        name: "",
        phoneno: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbarMessage("Failed to submit form. Please try again later.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSuccessSnackbarOpen(false);
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Box sx={{ width: "100%", padding: "20px" }}>
          <Typography
            fontWeight="bold"
            style={{ textAlign: "center", fontSize: "5vmin" }}
          >
            INQUIRY
          </Typography>

          <Box mt={3} fontSize="1.2rem" padding="5px 5px 5px 25px">
            <Grid
              container
              rowGap={2}
              columnSpacing={2}
              justifyContent="center"
              textAlign="start"
            >
         
              {/* Second row: Name, Phone No, Email, Message */}
              <Grid container spacing={3} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange("name")}
                    size="small"
                    placeholder="Name"
                    inputProps={{
                      minLength: 3,
                      maxLength: 50,
                      style: { backgroundColor: "white" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="phoneno"
                    value={formData.phoneno}
                    onChange={handleChange("phoneno")}
                    size="small"
                    placeholder="Phone No"
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                      style: { backgroundColor: "white", borderRadius: "5px" },
                    }}
                    error={!!errors.phoneno}
                    helperText={errors.phoneno}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange("email")}
                    size="small"
                    placeholder="Email"
                    inputProps={{
                      minLength: 10,
                      maxLength: 50,
                      style: { backgroundColor: "white" },
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="message"
                    value={formData.message}
                    multiline
                    // rows={3}
                    placeholder="Message"
                    onChange={handleChange("message")}
                    size="small"
                    inputProps={{ style: { backgroundColor: "white" } }}
                  />
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: "#0F5A8E",
                      color: "white",
                      fontSize: "2vmin",
                      padding: "auto 50px",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                      "&:hover": { backgroundColor: "#FCC190" },
                    }}
                  >
                    SUBMIT A REQUEST
                  </Button>
                </Grid>
              </Grid>

              {/* Snackbar Components */}
              <CustomSnackbar
                open={openSnackbar}
                autoHideDuration={3000}
                handleClose={handleCloseSnackbar}
                message={snackbarMessage}
                severity="error"
              />
              <CustomSnackbar
                open={successSnackbarOpen}
                autoHideDuration={3000}
                handleClose={handleCloseSnackbar}
                message="Form Submitted Successfully!"
                severity="success"
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </div>
  );
}

export default QuickEnquiry;
